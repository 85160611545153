<template>
  <div>
    <b-container fluid class="form p-5">
      <b-row>
        <b-col class="mb-4">
          {{ t('アカウントを作成') }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="field">{{ t('メールアドレス') }}
            <div>{{ email }}</div>
           </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="field mt-2">{{ t('検証コード') }}*
            <b-form-input class="mt-2" v-model="code" :placeholder="t('検証コードを入力してください')"></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <small class="navtext">{{ t('検証コードを忘れた場合') }} <a role="button" href="#" @click="resendSignUp">{{ t('検証コードを再送信') }}</a></small>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <small class="navtext">{{ t('アカウントがある場合') }} <a role="button" href="#" @click="backToSignUp">{{ t('ログインに戻る') }}</a></small>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="mt-5 confirm_button" variant="primary" @click="confirm">{{ t('確認') }}</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <small>{{ errorMassage }}</small>
        </b-col>
      </b-row>
    </b-container>
    <hr />
    <loading-screen v-if="isProcessing"></loading-screen>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import { I18n } from 'aws-amplify';
import Utils from '@/mixins/utils';
import LoadingScreen from '@/components/LoadingScreen.vue';

export default {
  name: 'ConfirmSignUp',
  components: {
    LoadingScreen,
  },
  mixins: [Utils],
  data() {
    return {
      email: '',
      code: '',
      errorMassage: '',
      isProcessing: false,
    };
  },
  mounted() {
    this.email = this.$route.query.email;
  },
  methods: {
    backToSignUp() {
      if (window.webkit != void 0 && window.webkit.messageHandlers.closePageHandler != void 0) {
        window.webkit.messageHandlers.closePageHandler.postMessage('');
      }
      this.$router.push({ name: 'login' });
    },
    async resendSignUp() {
      this.errorMassage = '';
      try {
        await Auth.resendSignUp(this.email, { domain: this.getEresaDomain() });
      }
      catch(e) {
        this.errorMassage = I18n.get(e.message);
      }
    },
    async confirm() {
      if (this.isProcessing) {
        return;
      }
      this.errorMassage = '';
      try {
        this.isProcessing = true;
        const option = {
          clientMetadata: { domain: this.getEresaDomain() }
        };
        await Auth.confirmSignUp(this.email, this.code, option);
        if (window.webkit != void 0 && window.webkit.messageHandlers.closePageHandler != void 0) {
          window.webkit.messageHandlers.closePageHandler.postMessage('');
        }
        this.$router.push({ name: 'login' });
      }
      catch(e) {
        this.errorMassage = I18n.get(e.message);
      }
      finally {
        this.isProcessing = false;
      }
    }
  },
};
</script>

<style scoped>
.form {
  min-width: 320px;
  max-width: 480px;
  background-color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
}
.field {
  width: 100%;
  font-size: 14px;
}
.form-control {
  padding: 24px 16px 24px 16px !important;
}
::placeholder {
  font-size: 10pt;
}
.navtext {
  font-size: 9pt;
}
.navtext a {
  color: var(--link-color);
}
.confirm_button {
  background: linear-gradient(107.27deg, #4b82ed 0%, #1d4daa 100%);
  color: white;
  width: 100%;
}
</style>
